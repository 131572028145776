* {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%
}

body {
    height: 100%;
/*    background: #5d4037;*/
    background:white;
    font-family: 'Open Sans', 'Tahoma', 'Verdana';
    font-size: 12px
}

.content {
    height: 100%;
    background: inherit;
    width: 100%;
}

.header {
    color: white;
    height: 137px;
    float: left;
    width: 100%;
    position: relative;
    z-index: 2
}

.header .content {
    position: relative;
    background: #DEDEDE  no-repeat;
}

.competitions-name {
    float: right;
    margin: 20px;
    width: 600px;
    font-size: 17px;
    color: #6B311B;
    font-weight: 600;
    text-align: right
}

.main-title {
    max-width: 400px;
    float: left
}

.content-wrapper {
    background: #91908E;
    width: 100%;
    min-height: calc(100% - 112px - 137px);
    float: left
}

.menu {
    color: #fff;
    width: 240px;
    float: left;
    margin-bottom: 0px;
    margin-top: 0px;
    /*display: none;*/
}

.menu a {
    color: white;
    /*padding: 14px 16px;*/
    text-decoration: none;
    font-size: 17px;
    display: block;
  }
  
  .menu a.icon {
    background: black;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }

/* Check if the screen size is at least 600px */
@media only screen and (min-width: 800px) {
    .menu {
        display: block;
    }
}


.menu ul {
    
    list-style-type: none
}

.menu li {
    width: 100%
}

.menu li>a {
    padding-left: 15px;
    display: block;
    text-align: left;
    height: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 900;
    text-decoration: none;
    line-height: 28px;
    font-family: 'Play', sans-serif
}

.menu li>a.active {
    color: #FF0;
    
    /*color: #fff;*/
    
}

.glowt {    
    color: rgb(255, 255, 255) !important;
    /*animation: glowt 1s ease-in-out infinite alternate !important;*/
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ffffff, 0 0 20px #ffffff, 0 0 25px #f8f8f8, 0 0 30px #ffffff, 0 0 35px #ffffff;
}

@-webkit-keyframes glowt {
    from {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #e60073, 0 0 20px #e60073, 0 0 25px #e60073, 0 0 30px #e60073, 0 0 35px #e60073 !important;
    }

    to {
        text-shadow: 0 0 5px #fff, 0 0 15px #ff4da6, 0 0 20px #ff4da6, 0 0 25px #ff4da6, 0 0 30px #ff4da6, 0 0 35px #ff4da6, 0 0 40px #ff4da6 !important;
    }
}


.menu li>a.active:before {
    /*content: '→';*/
    font-family: 'Verdana'
}

.menu li>a:focus,
.menu li>a:hover {
    outline: none;
    background: #BABBBD
}

.menu li>a.active:hover {
    background: #BABBBD;
    color: #000
}

.menu li>a:before {
   /* content: '\00BB';*/
    margin-right: 4px
}

.m-content {
    display: block;
    float: left;
    background: #fff;
    width: 100%;
    min-height: calc(100vh - 112px - 137px);
    height: 100%;
    -webkit-box-shadow: -6px -7px 10px -4px #969696;
    -moz-box-shadow: -6px -7px 10px -4px #969696;
    box-shadow: -6px -7px 10px -4px #656565;
    position: relative;
    z-index: 1
}

.main-content {
    
    font-size: 14px;
    position: relative;
    float: left;
    height: 100%;
    line-height: 25px;
    padding: 25px;
    padding-bottom: 120px;
    color: #171717;
    min-height: 800px;
    width: 100%;
    border-bottom-left-radius: 4px;
    font-family: "Tahoma", "Verdana"
}

/* Check if the screen size is at least 600px */
@media only screen and (min-width: 800px) {
    .main-content {
        width: calc(100% - 200px);
    }
    .m-content {
        width: calc(100% - 240px);
    }
}


.main-content h2 {
    margin-bottom: 30px;
    font-weight: 700;
    width: 100%;
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
}

.main-content h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 23px
}

.main-content>h3 {
    margin-top: 30px
}

.main-content p {
	/*display: inline-block;*/
    margin-bottom: 10px;
    line-height: 20px;

}


.red-text{
    color: #f41b1b;
}

.red-text-header{
    color: #ff2222;
}

.main-content img{
	display: inline-block;
	vertical-align: middle;
	padding: 20px;
	margin-bottom: 10px;
}

.main-content p.huge{
	color: #180A51;
	font-size: 35px;
	line-height: 40px;
	font-weight: 700;
	vertical-align: middle;
	font-family: "Open Sans", sans-serif;
}

.main-content ul {
    margin-left: 15px
}

.main-content iframe{
	width: 100%;
	
}

.main-content.sponsorzy img {
    display: block;
    position: relative;
    max-width: 400px;
    margin-bottom: 50px;
    margin-left: 10px
}

.main-content.glowna img {
    display: inline;
    vertical-align: middle;
    position: relative;
    max-width: 300px;
    margin-right: 40px;
    margin-bottom: 40px
}

.main-content.glowna h2{
    display:none;
}

.main-content.regulamin iframe{
	max-width: 1800px !important;
}



.main-content.nagrody p{
    display: inline-block;
}

.main-content a.inherit {
    color: inherit
}

.main-content a {
    color: #3B5998;
    text-decoration: none
}

.main-content a:focus,
.main-content a:hover {
    text-decoration: underline
}

.main-content .table{
    border: 1px solid #C0C0C0;
    border-collapse: collapse;
}

.main-content .table td{
    border: 1px solid #CACACA;
    padding: 5px 10px;
}

.main-content .table th{
    border: 1px solid #C0C0C0;
    padding: 5px 10px;
}

.main-content .table tr{
    text-align: left;
}

.main-content .table td:nth-child(3){
    text-align: justify;
}

.main-content .table tr:nth-child(2n+1){
    background: #FFF;
}

.main-content .table tr:nth-child(2n){
    background: #F2F2F2;
}

.main-content .table tr:nth-child(1){
    background: #DFDFDF;
}

.main-content .indent {
    margin-left: 45px;
    margin-top: 10px;
    margin-bottom: 10px
}

.main-content .notice {
    display: block;
    border: 1px solid #B9B9B9;
    background: #FFFCEF;
    color: #A11;
    border-radius: 3px;
    padding: 5px 15px;
}

.main-content .notice p {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0;
}

.main-content #kolacja{
	display: block;
	margin-left: 40px;
	max-width: 500px;
    background: #FFFDEA;
	border: 1px solid #B9B9B9;
	border-radius: 4px;
	padding: 15px;
}

.main-content h4{
    font-size: 18px;
}

.main-content #kolacja.hidden{
    display: none;
}


.main-sponsors {
    float:right; 
    display:none; 
    width: 200px;
    margin-right: 0px;
    
    

}

/* Check if the screen size is at least 600px */
@media only screen and (min-width: 800px) {
    .main-sponsors {
        display: block;
    }
}


.main-sponsors a {
    /*float: left;*/
    display: inline-block;
    margin: 15px 30px
}

.main-sponsors img {
    width: 100%;
    float: left
}

.main-sponsors h3 {
    text-align: center;
    margin-top: 20px;
    font-size: 20px

}


.fb {
    display: none;
    position: absolute;    
    float: left;
    bottom: 0px;
    width: 500px;
    height: 70px;
    padding-left:25px;
    margin-bottom:20px;
    border-width: 10px;
}

/* Check if the screen size is at least 600px */
@media only screen and (min-width: 950px) {
    .fb {
        display: block;
    }
}




.footer {
	background: #382C28;
	color: #fff;
    height: 112px;
    width: 100%;
    min-width: calc(200px + 240px + 100px);
    display: none;
}

     /* Check if the screen size is at least 600px */
     @media only screen and (min-width: 600px) {
        .footer {
            display: block;
        }
    }

.foot-right {
    float: left;
    width: 240px;
    height: 100%
}

.foot-mid {
    float: left;
    height: 100%;
    width: calc(100% - 240px - 200px);
    
}

.foot-left {
    float: left;
    height: 100%;
    width: 200px
}

.footer img {
    width: 100%;
    height: 100%;
    float: left
}

.sticky {
    top: 0;
    position: fixed;
    min-width: 270px
}



.sponsor-form{
    padding: 20px;
    padding-top:0px;
    font-size: 15px;

    width: 340px;
}
.sponsor-form{
    border: solid 1px #91908e;
}

.sponsor-form p{
    font-weight: 700;
    font-size: 18px;
    margin-top:20px;
}

.sponsor-form input[type=radio], .sponsor-form input[type=checkbox]{
    margin-right: 3px;
}
.sponsor-form span{
    margin-right: 6px;
}


.sponsor-form textarea{
    width: 300px;
    height: 100px;
    padding: 6px;
    resize: vertical;
}


.sponsor-form input[type=text]{
    padding: 3px 6px;
    width: 300px;
}

.sponsor-form input[type=submit]{
    padding: 5px 8px;
    cursor: pointer;
}

.main-content.regulamin{
    max-width: 100%;
}

iframe{
    width: 100%;
    height: 600px;
    border: 1.5px solid #B9B9B9;
    border-radius: 3px;
}

.fcb {
    border: none; 
    visibility: visible; 
    width: 475px; 
    height: 70px;
    
    max-width: 100%;
}

.sponsors {
}
/*


.topnav {
    max-width: 480px;
    margin: auto;
    background-color: #555;
    height: 500px;
    color: white;
    border-radius: 10px;
  }
  
  .menu {
    overflow: hidden;
    background-color: #333;
    position: relative;
  }
  
  .topnav #myLinks {
    display: none;
  }
  
  .menu a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }
  
  .menu a.icon {
    background: black;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .menu a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .menu .active {
    background-color: #04AA6D;
    color: white;
  }*/

  .list ul {
    display: block;
    margin-top: 0px;
    /*background-color: #A11;*/
    
  }
  .list {
    display: block;
    margin-top: 0px;
    /*background-color: #A11;*/
    
  }
  
  .menuIcon {
    height: 48px;
    width: 24px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-top: 5px;    
    background-color: rgb(145, 144, 142);
}

.icon {
    background-color: rgb(145, 144, 142);
    border-width: 0px;
    display: block;
}

.menu {
    margin-top: 0px;
    margin-bottom: 0px;
}

/* Check if the screen size is at least 600px */
@media only screen and (min-width: 800px) {
    .icon {
        display: none;
    }

    .menu {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
